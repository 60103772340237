<template>
  <base-index-layout :title="$tc('order.name', 2)">
    <SaleOrderCustomerIndexTable />
  </base-index-layout>
</template>

<script>
import SaleOrderCustomerIndexTable from './components/SaleOrderCustomerIndexTable'
export default {
  components: {
    SaleOrderCustomerIndexTable
  }
}
</script>

<style lang="scss" scoped></style>
