<template>
  <v-data-table :headers="headers" :items="orders" :loading="isLoading">
    <template v-slot:item.status="{ item }">
      <base-status-chip small :status="item.attributes.status" />
    </template>

    <template v-slot:item.attributes.customAttributes.name="{ item }">
      <div class="py-6">
        <p class="info--text subtitle-1 font-weight-medium mb-1 ">
          {{ item.attributes.customAttributes.name }}
        </p>
        <span v-if="item.attributes.createdAt" class="text--secondary">{{
          item.attributes.createdAt | moment('MMM Do YYYY')
        }}</span>
      </div>
    </template>

    <template v-slot:item.party>
      Tonewoods S.L
    </template>

    <template v-slot:item.etd="{ item }">
      {{
        getLoadDestination(item.id, 'Destination') | moment('MMM Do YYYY')
      }}</template
    >

    <template v-slot:item.id="{ item }">
      <v-btn
        class="my-4"
        icon
        color="info"
        @click="
          $router.push({
            name: 'sale-order-show',
            params: { orderId: item.id }
          })
        "
      >
        <v-icon small>mdi-eye-outline</v-icon></v-btn
      >
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex'
import { ORGANIZATION_ID } from '@/common/config.js'
export default {
  data() {
    return {
      isLoading: true
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      orders: 'saleOrder/orders',
      parties: 'party/parties',
      getLoadByCategoryAndOrderId: 'load/getLoadByCategoryAndOrderId'
    }),
    headers() {
      return this.createTableHeaders()
    }
  },

  async created() {
    this.isLoading = true
    await this.filterOrders('sale')
    this.isLoading = false
  },

  methods: {
    getLoadDestination(orderId, category) {
      const load = this.getLoadByCategoryAndOrderId(orderId, category)
      return load ? load.attributes.estimatedUnloadDateTo : new Date()
    },
    async filterOrders(subCategory) {
      const response = await this.$store.dispatch('saleOrder/filter', {
        subCategory
        // organizationId: ORGANIZATION_ID()
      })

      this.filterLoadsParties(response)
    },

    async filterLoadsParties(orders) {
      const orderIds = orders.map(order => order.id).join(',')
      await this.$store.dispatch('party/filter', { orderIds })
      await this.$store.dispatch('load/filter', {
        orderIds,
        organizationId: ORGANIZATION_ID()
      })
    },
    createTableHeaders() {
      return [
        {
          text: this.$tc('common.shipment', 1),
          align: 'start',
          sortable: false,
          value: 'attributes.customAttributes.name'
        },
        {
          text: this.$tc('user.supplier', 1),
          value: 'party',
          sortable: false
        },
        {
          text: this.$t('load.eta', 1),
          sortable: false,
          value: 'etd'
        },
        {
          text: '',
          value: 'id',
          sortable: false,
          align: 'end'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped></style>
